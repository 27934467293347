<template>
  <RevCard class="mx-auto p-32 md:my-56">
    <h3 class="heading-3 mb-16">
      {{ title }}
    </h3>
    <p class="body-2">
      {{ message }}
    </p>

    <RevButton
      v-if="type === 'not-connected'"
      class="mt-16"
      :to="loginRoute"
      variant="primary"
      >{{ i18n(translations.signIn) }}</RevButton
    >

    <RevLink v-if="type === 'wrong-account'" @click="logout">
      {{ i18n(translations.signOut) }}
    </RevLink>
  </RevCard>
</template>

<script lang="ts" setup>
import { useRoute } from '#imports'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useLogout } from '@backmarket/nuxt-module-oauth/useLogout'
import { RevButton } from '@ds/components/Button'
import { RevCard } from '@ds/components/Card'
import { RevLink } from '@ds/components/Link'
import type { Location } from '@ds/types/Location'

import { ROUTES } from '~/scopes/auth/route-names'

import type { ReviewCollectionDisplayedError } from '../../models/displayed-error'

import translations from './ErrorBlock.translations'

const { logout } = useLogout()
defineProps<ReviewCollectionDisplayedError>()

const route = useRoute()
const i18n = useI18n()
const loginRoute: Location = {
  name: ROUTES.AUTH.LOGIN,
  query: {
    next: route.path,
  },
}
</script>
