<template>
  <div class="flex flex-col gap-32 md:flex-row" data-test="review-final-step">
    <RevCard class="p-24 md:w-1/2">
      <div class="flex h-full grow flex-col justify-between gap-24">
        <div class="flex justify-between gap-24">
          <div class="flex flex-col">
            <h2 class="text-static-default-hi heading-2">
              {{ summaryTitle }}
            </h2>
            <div class="text-static-default-low body-1">
              {{ summaryDescription }}
            </div>
          </div>

          <RevButtonRounded
            :icon="IconEdit"
            variant="primary"
            @click="() => emit('edit-review-clicked')"
          />
        </div>
        <RevRating v-if="averageRate" :score="averageRate" />

        <RevDivider v-if="averageRate" />

        <ProductInfos
          :averageRate="averageRate"
          :productImage="productImage"
          :productTitle="productTitle"
        />
      </div>
    </RevCard>

    <RevCard class="p-24 md:w-1/2">
      <div class="flex h-full grow flex-col justify-between gap-24">
        <PositiveNextStepContent v-if="isPositiveReview" />
        <NegativeNextStepContent v-else />
      </div>
    </RevCard>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevButtonRounded } from '@ds/components/ButtonRounded'
import { RevCard } from '@ds/components/Card'
import { RevDivider } from '@ds/components/Divider'
import { RevRating } from '@ds/components/Rating'
import { IconEdit } from '@ds/icons/IconEdit'

import { useReviewCollectionSource } from '../../composables/useReviewCollectionSource'
import ProductInfos from '../ProductInfosCard/ProductInfos.vue'

import NegativeNextStepContent from './NegativeNextStepContent.vue'
import PositiveNextStepContent from './PositiveNextStepContent.vue'
import translations from './ReviewSummary.translations'

const props = defineProps<{
  isPositiveReview: boolean
  averageRate?: number
  productTitle: string
  productImage: string
}>()

const emit = defineEmits<{
  (e: 'edit-review-clicked'): void
}>()

const i18n = useI18n()
const { isSolvedClaim } = useReviewCollectionSource()

const summaryTitle = computed(() => {
  if (props.isPositiveReview) {
    return isSolvedClaim.value
      ? i18n(translations.positiveTitleSolvedClaim)
      : i18n(translations.positiveTitle)
  }

  return i18n(translations.negativeTitle)
})
const summaryDescription = computed(() => {
  if (props.isPositiveReview) {
    return isSolvedClaim.value
      ? i18n(translations.positiveTextSolvedClaim)
      : i18n(translations.positiveText)
  }

  return i18n(translations.negativeText)
})
</script>
