<template>
  <div class="flex flex-col gap-32 lg:flex-row">
    <RevButtonBase
      class="rounded-lg flex h-[196px] w-full shrink-0 items-center justify-center bg-[#FE9CE5] md:bg-static-info-low lg:w-[232px]"
      :to="{
        type: 'external',
        href: APP_DOWNLOAD_BASE_URL,
      }"
      @click="trackQrCodeClick"
    >
      <!-- fontControlled="false" is necessary to avoid applying the 'nuxt-icon' class 
      Doc: https://github.com/cpsoinos/nuxt-svgo?tab=readme-ov-file#component-props
      -->
      <QRCode
        class="hidden md:block"
        :class="$style.qrCodeSvg"
        :filled="true"
        :font-controlled="false"
        height="160"
        viewBox="0 0 1130 1130"
        width="160"
      />

      <RevIllustration
        alt=""
        class="rounded-lg block h-auto object-cover md:hidden"
        :height="200"
        src="/img/reviews/positive-review-summary.svg"
        :width="232"
      />
    </RevButtonBase>
    <div class="flex flex-col gap-8">
      <h2 class="text-static-default-hi heading-2">
        {{ i18n(translations.titleGood) }}
      </h2>
      <div class="text-static-default-low body-1">
        {{ i18n(translations.bodyGood) }}
      </div>
    </div>
  </div>
  <!-- As the AppStore button has a 2px left margin, we use gap-14 to have 16px of spacing between the 2 buttons -->
  <AppStoreButtons class="flex gap-14" />
</template>

<script lang="ts" setup>
import { useRoute } from '#imports'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { RevButtonBase } from '@ds/components/ButtonBase'
import { RevIllustration } from '@ds/components/Illustration'

import QRCode from './QRCode.svg'
import translations from './ReviewSummary.translations'

const APP_DOWNLOAD_BASE_URL = 'https://app.backmarket.com/j4lA/reviewcollection'

const i18n = useI18n()

const { trackClick } = useTracking()
const route = useRoute()

function trackQrCodeClick() {
  trackClick({
    zone: route.name?.toString?.() || '',
    name: 'download_app',
    value: 'qr_code',
  })
}
</script>

<style module>
/**
* This allows to fill the QR code with the bg-static-info-low
* All path elements of the SVG which have a fill prop are made transparent (instead of white)
*/
.qrCodeSvg path:nth-child(odd) {
  fill: transparent;
}
</style>
