import { useRoute } from '#imports'

import type { ReviewCollectionGetResponse } from '@backmarket/http-api/src/api-specs-reviews/types/review-form'
import { useAuthStore } from '@backmarket/nuxt-module-oauth/useAuthStore'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { insertIf } from '@backmarket/utils/collection/insertIf'
import { storeToRefs } from 'pinia'

import type { ReviewForm, ReviewFormQuestion } from '../models/review-form'

import { useReviewCollectionSource } from './useReviewCollectionSource'

const buildNumberOfCharactersProp = ({
  values,
  questions,
}: {
  values: ReviewForm
  questions: ReviewFormQuestion[]
}): { numberOfCharacters: number } | Record<string, never> => {
  const questionsWithText = questions.filter(
    (question) => question.type === 'textarea',
  )

  if (questionsWithText.length === 0) return {}

  const { identifier } = questionsWithText[0]
  const numberOfCharacters = values[identifier]?.toString?.()?.length

  return insertIf(!!numberOfCharacters, {
    numberOfCharacters: numberOfCharacters || 0,
  })
}

export function useReviewCollectionTracking() {
  const { trackClick } = useTracking()
  const { source } = useReviewCollectionSource()

  const { params, name } = useRoute()

  const { authenticated } = storeToRefs(useAuthStore())

  function trackRateChange({
    rate,
    step: zeroBasedStep,
  }: {
    rate: number
    step: number
  }) {
    // 1-based index
    const step = zeroBasedStep + 1

    trackClick({
      name: 'review',
      zone: `reviews_page_${step}`,
      reviewRate: rate,
    })
  }

  function trackStepSubmission({
    values,
    step: zeroBasedStep,
    questions,
    product,
    averageRate,
  }: {
    values: ReviewForm
    step: number
    questions: ReviewFormQuestion[]
    product?: ReviewCollectionGetResponse['product']
    averageRate?: number
  }) {
    const numberOfCharacters = buildNumberOfCharactersProp({
      values,
      questions,
    })

    // 1-based index
    const step = zeroBasedStep + 1

    trackClick({
      name: `review_submit_${step}`,
      zone: `reviews_page_${step}`,
      pageType: String(name),
      ...numberOfCharacters,
      isAuthenticated: authenticated.value,
      productTitle: product?.title || '',
      orderlineId: params.orderlineId,
      source: source.value,
      ...insertIf(!!averageRate, { reviewRate: averageRate || 0 }),
    })
  }

  function trackEditReview({
    isPositiveReview,
  }: {
    isPositiveReview: boolean
  }) {
    trackClick({
      name: 'edit',
      zone: name as string,
      value: isPositiveReview ? 'positive' : 'negative',
    })
  }

  return { trackRateChange, trackStepSubmission, trackEditReview }
}
